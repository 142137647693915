import { Link } from "react-router-dom";
import { truncate } from "../helpers/helpers";

const sizes = {
  sm: {
    height: 22,
    fontSize: 8,
    paddingX: 12,
    paddingY: 7,
  },
  md: {
    height: 26,
    fontSize: 8,
    paddingX: 20,
    paddingY: 7,
  },
  lg: {
    height: 41,
    fontSize: 13,
    paddingX: 22,
    paddingY: 13,
  },
  xl: {
    height: 56,
    fontSize: 20,
    paddingX: 34,
    paddingY: 13,
  },
};

const abbreviations = {
  "strawberry stars": "strawb stars",
};

export const Tag = (props) => {
  const { color, size, label, path, onClick, outline } = props;
  const height = sizes[size].height;
  const fontSize = sizes[size].fontSize;
  const paddingX = sizes[size].paddingX;
  const paddingY = sizes[size].paddingY;
  const radius = size === "xl" ? 50 : 30;

  let tagLabel = label;

  if (label.length > 13 && size === "sm") {
    if (label.toLowerCase() in abbreviations) {
      tagLabel = abbreviations[label.toLowerCase()];
    } else {
      tagLabel = truncate(tagLabel, 10);
    }
  }

  const styles = {
    height: height,
    paddingLeft: paddingX,
    paddingRight: paddingX,
    paddingTop: paddingY,
    paddingBottom: paddingY,
    borderRadius: radius,
  };

  if (outline) {
    styles["border"] = `2px solid ${color}`;
    styles["color"] = color;
    styles["backgroundColor"] = "white";
  } else {
    styles["backgroundColor"] = color;
    styles["color"] = "white";
  }

  if (onClick) {
    styles["cursor"] = "pointer";
  }

  const tag = (
    <div
      className="d-flex justify-content-center align-items-center"
      style={styles}
      onClick={onClick}
    >
      <div className="text-bold" style={{ fontSize: fontSize }}>
        {tagLabel.toUpperCase()}
      </div>
    </div>
  );

  return path ? <Link to={path}>{tag}</Link> : tag;
};
