import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const MILLISECONDS_IN_DAY = 86400000;
export const MILLISECONDS_IN_WEEK = 7 * MILLISECONDS_IN_DAY;

export const MONTHS = [
  {
    abbrev: "Jan",
    full: "January",
  },
  {
    abbrev: "Feb",
    full: "February",
  },
  {
    abbrev: "Mar",
    full: "March",
  },
  {
    abbrev: "Apr",
    full: "April",
  },
  {
    abbrev: "May",
    full: "May",
  },
  {
    abbrev: "Jun",
    full: "June",
  },
  {
    abbrev: "Jul",
    full: "July",
  },
  {
    abbrev: "Aug",
    full: "August",
  },
  {
    abbrev: "Sept",
    full: "September",
  },
  {
    abbrev: "Oct",
    full: "October",
  },
  {
    abbrev: "Nov",
    full: "November",
  },
  {
    abbrev: "Dec",
    full: "December",
  },
];

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

// from https://stackoverflow.com/questions/1531093/how-do-i-get-the-current-date-in-javascript
export const getTodayDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
};

// with help from https://stackoverflow.com/questions/15196451/regular-expression-to-validate-datetime-format-mm-dd-yyyy
export const validateDate = (testdate) => {
  var date_regex = /^(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|1\d|2\d|3[01]|[1-9])\/(((19|20)\d{2})|\d{2})$/;
  return date_regex.test(testdate);
};

export const isInvalidDate = (dateStr) => {
  const inputDate = new Date(dateStr);
  const today = new Date();
  return inputDate > today;
};

export const getLastSunday = () => {
  const d = new Date();
  const dayOfWeek = d.getDay();
  const lastSunday = new Date().setDate(new Date().getDate() - dayOfWeek);
  return lastSunday;
};

export const getFirstOfMonth = () => {
  const d = new Date();
  const month = String(d.getMonth() + 1);
  const year = d.getFullYear();
  const firstOfMonth = new Date(month + "/01/" + year);
  return firstOfMonth;
};

// from https://masteringjs.io/tutorials/fundamentals/capitalize-first-letter#:~:text=Capitalizing%20the%20first%20letter%20of,the%20string%20slice()%20method.&text=The%20first%20part%20converts%20the,the%20rest%20of%20the%20string.
export const capitalize = (str) => {
  const capitalizeWord = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return str.split(" ").map(capitalizeWord).join(" ");
};

// Returns the user's username if it exists. Returns an empty string otherwise.
export const getUsername = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const newUsername = usersRef
    .doc(uid)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        return snapshot.get("username");
      } else {
        return "";
      }
    });
  return newUsername;
};

const createNewUsername = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");

  // Generate new username
  const randomString = require("crypto").randomBytes(2).toString("hex");
  const newUsername = ("user" + randomString).toLowerCase();

  // Verify that username doesn't already exist. If it doesn't, add it.
  // If it does, call this function again.
  return usersRef
    .where("username", "==", newUsername)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return newUsername;
      } else {
        createNewUsername(uid);
      }
    });
};

// Creates and returns new user object.
const createNewUser = async (uid, currentUser) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");

  const newUsername = await createNewUsername(uid);
  const newUser = {
    username: newUsername,
    displayName: currentUser.displayName,
    photoUrl: currentUser.photoURL,
    following: [],
    public: false,
    location: "",
  };

  usersRef.doc(uid).set(newUser);

  return newUser;
};

export const storeNewUsername = async (uid, newUsername) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const newUsernameLowerCase = newUsername.toLowerCase();

  // Check if username has already been taken.
  return usersRef
    .where("username", "==", newUsernameLowerCase)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        usersRef
          .doc(uid)
          .set({ username: newUsernameLowerCase }, { merge: true });
        return true;
      } else {
        return false;
      }
    });
};

export const getFriendReviews = async (followingList) => {
  const firestore = firebase.firestore();
  const reviewsRef = firestore.collection("reviews");
  const lastSunday = getLastSunday();
  const friendReviews = [];
  for (const following of followingList) {
    const snapshot = await reviewsRef.where("userId", "==", following).get();
    if (!snapshot.empty) {
      snapshot.forEach((doc) => {
        friendReviews.push({ ...doc.data(), id: doc.id });
      });
    }
  }
  const filteredAndSortedReviews = friendReviews.filter(
    (review) => review.createdAt.toDate() >= lastSunday
  );
  filteredAndSortedReviews.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  return filteredAndSortedReviews;
};

export const getUser = async (currentUser) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const uid = currentUser.uid;
  return usersRef
    .doc(uid)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        return snapshot.data();
      } else {
        return createNewUser(uid, currentUser).catch((e) =>
          console.log("getUser: error creating new user: ", e.message)
        );
      }
    });
};

export const getUserWithUid = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  return usersRef
    .doc(uid)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        return snapshot.data();
      } else {
        return {};
      }
    });
};

export const getUserWithUsername = async (username) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  let user = {};
  return usersRef
    .where("username", "==", username)
    .limit(1)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return false;
      } else {
        snapshot.forEach((doc) => {
          user = {
            uid: doc.id,
            data: doc.data(),
          };
        });
        return user;
      }
    });
};

export const publicUserWithUsernameExists = async (username) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const searchQuery = username.toLowerCase();
  return usersRef
    .where("public", "==", true)
    .where("username", "==", searchQuery)
    .limit(1)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return false;
      } else {
        return true;
      }
    });
};

export const getPublicUserWithUsername = async (username) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const searchQuery = username.toLowerCase();
  let user = {};
  return usersRef
    .where("public", "==", true)
    .where("username", "==", searchQuery)
    .limit(1)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return false;
      } else {
        snapshot.forEach((doc) => {
          user = {
            uid: doc.id,
            data: doc.data(),
          };
        });
        return user;
      }
    });
};

export const getAllPublicUsers = async () => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  return usersRef
    .where("public", "==", true)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return [];
      } else {
        const allPublicUsernames = [];
        snapshot.forEach((doc) => {
          allPublicUsernames.push({
            username: doc.data().username,
            photoUrl: doc.data().photoUrl,
          });
        });
        return allPublicUsernames;
      }
    });
};

// Gets all users' photos, display names, and usernames.
// For use in the feed searchbar. Must have name and value.
// {name: "thu", photoUrl: "...", value: "Thu Nguyen"}
export const getAllUsers = async () => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  return usersRef.get().then((snapshot) => {
    if (snapshot.empty) {
      return [];
    } else {
      const allUsers = [];
      snapshot.forEach((doc) => {
        allUsers.push({
          name: doc.data().username,
          photoUrl: doc.data().photoUrl,
          value: doc.data().username,
          displayName: doc.data().displayName,
        });
      });
      return allUsers;
    }
  });
};

export const userWithUsernameExists = async (username) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  return usersRef
    .where("username", "==", username)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return false;
      } else {
        return true;
      }
    });
};

// Returns the user's location if it exists. Returns empty string otherwise.
export const getLocation = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const location = usersRef
    .doc(uid)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        return snapshot.get("location");
      } else {
        return "";
      }
    });
  return location;
};

// Returns the user's following list or an empty array.
export const getFollowingList = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const location = usersRef
    .doc(uid)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        return snapshot.get("following");
      } else {
        return [];
      }
    });
  return location;
};

// Returns the user's display name if it exists. Returns empty string otherwise.
export const getDisplayName = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const name = usersRef
    .doc(uid)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        return snapshot.get("displayName");
      } else {
        return "";
      }
    });
  return name;
};

// Returns the user's profile url if it exists. Returns empty string otherwise.
export const getPhotoUrl = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const url = usersRef
    .doc(uid)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        return snapshot.get("photoUrl");
      } else {
        return "";
      }
    });
  return url;
};

// Returns whether the user's profile is public.
export const getProfileIsPublic = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const name = usersRef
    .doc(uid)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        return snapshot.get("public");
      } else {
        return false;
      }
    });
  return name;
};

export const storeNewLocation = async (uid, newLocation) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  usersRef.doc(uid).set({ location: newLocation }, { merge: true });
  return true;
};

export const storeProfileIsPublic = async (uid, isPublic) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  usersRef.doc(uid).set({ public: isPublic }, { merge: true });
  return true;
};

export const setFollowing = async (
  uid,
  followingUid,
  follow,
  prevFollowingList
) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  if (follow) {
    prevFollowingList.push(followingUid);
    usersRef.doc(uid).update({
      following: prevFollowingList,
    });
  } else {
    const newFollowingList = prevFollowingList.filter(
      (id) => id !== followingUid
    );
    usersRef.doc(uid).update({
      following: newFollowingList,
    });
  }
  return true;
};

export const setFollower = async (uid, followerUid, follow) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  if (follow) {
    usersRef.doc(uid).collection("followers").add({
      createdAt: new Date(),
      follower: followerUid,
    });
  } else {
    usersRef
      .doc(uid)
      .collection("followers")
      .where("follower", "==", followerUid)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            doc.ref.delete();
          });
        }
      });
  }
};

export const deleteReview = async (docId) => {
  const firestore = firebase.firestore();
  const reviewsRef = firestore.collection("reviews");
  await reviewsRef.doc(docId).delete();
};

export const deleteUser = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const followingUserIds = await getFollowingList(uid);
  await deleteAllReviewsForUser(uid);
  await deleteFollowersFromUserFollowerList(uid);
  // Deleting followers must come first
  await deleteUserFromFollowerLists(uid, followingUserIds);
  await deleteUserFromFollowingLists(uid);
  await usersRef.doc(uid).delete();
};

const deleteAllReviewsForUser = async (uid) => {
  const firestore = firebase.firestore();
  const reviewsRef = firestore.collection("reviews");
  reviewsRef
    .where("userId", "==", uid)
    .get()
    .then((snapshot) => {
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          doc.ref.delete();
        });
      }
    });
};

const deleteUserFromFollowingLists = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  usersRef
    .where("following", "array-contains", uid)
    .get()
    .then((snapshot) => {
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          const prevFollowingList = doc.data().following;
          const newFollowingList = prevFollowingList.filter((id) => id !== uid);
          usersRef.doc(doc.id).update({
            following: newFollowingList,
          });
        });
      }
    });
};

// Deletes this user from all follower lists of users they follow.
const deleteUserFromFollowerLists = async (uid, followingList) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  if (followingList.length > 0) {
    usersRef
      .where("userId", "in", followingList)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            doc
              .collection("followers")
              .where("follower", "==", uid)
              .get()
              .then((followerSnapshot) => {
                if (!followerSnapshot.empty) {
                  followerSnapshot.forEach((doc) => {
                    doc.ref.delete();
                  });
                }
              });
          });
        }
      });
  }
};

const deleteFollowersFromUserFollowerList = async (uid) => {
  const firestore = firebase.firestore();
  const followersRef = firestore
    .collection("users")
    .doc(uid)
    .collection("followers");
  followersRef.get().then((snapshot) => {
    if (!snapshot.empty) {
      snapshot.forEach((doc) => {
        doc.ref.delete();
      });
    }
  });
};

export const getFollowerNotifications = async (uid) => {
  const firestore = firebase.firestore();
  const usersRef = firestore.collection("users");
  const followers = await usersRef
    .doc(uid)
    .collection("followers")
    .get()
    .then((response) => {
      const allFollowers = [];
      response.forEach((doc) => {
        allFollowers.push(doc.data());
      });
      return allFollowers;
    });

  return followers;
};
