import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { default as React, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUser } from "../helpers/helpers";
import "../stylesheets/Feed.css";
import "../stylesheets/NotFound.css";
import { Footer } from "./Footer";
import { NotFound } from "./NotFound";

export const NotFoundAuth = () => {
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  const [userSettings, setUserSettings] = useState({
    username: "",
    location: "",
    displayName: "",
    photoUrl: "",
    public: false,
    following: [],
  });
  const [loadingUserSettings, setLoadingUserSettings] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [loadingReviews, setLoadingReviews] = useState(true);

  const reviewsRef = firestore.collection("reviews");
  const location = useLocation();

  // Load this user's reviews
  React.useEffect(() => {
    if (loadingReviews) {
      const fetchReviews = async () => {
        const snapshots = await reviewsRef
          .where("userId", "==", auth.currentUser.uid)
          .orderBy("createdAt", "desc")
          .get();
        if (snapshots.empty) {
          setReviews([]);
          setLoadingReviews(false);
          console.log("404: No matching reviews for this user.");
          return;
        }
        const allReviews = [];
        snapshots.forEach((doc) => {
          allReviews.push({ ...doc.data(), id: doc.id });
        });
        setReviews(allReviews);
        setLoadingReviews(false);
      };

      fetchReviews();
    }
  }, [auth.currentUser.uid, reviewsRef, setLoadingReviews, loadingReviews]);

  // Load this user's settings
  React.useEffect(() => {
    if (loadingUserSettings) {
      const fetchData = async () => {
        const user = await getUser(auth.currentUser);
        const username = user.username;
        const location = user.location;
        const displayName = user.displayName;
        const photoUrl = auth.currentUser.photoURL;
        const isPublic = user.public;
        const following = user.following;
        setUserSettings({
          username,
          location,
          displayName,
          photoUrl,
          public: isPublic,
          following,
        });
        setLoadingUserSettings(false);
      };

      fetchData();
    }
  }, [auth.currentUser, userSettings, loadingUserSettings]);

  return (
    <div className="Root-page">
      <NotFound isAuth={true} />
      <Footer
        location={location.pathname}
        reviews={reviews}
        setLoadingReviews={setLoadingReviews}
        loadingUserSettings={loadingUserSettings}
        userSettings={userSettings}
        setUserSettings={setUserSettings}
      />
    </div>
  );
};
