import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import firebase from "firebase/app";
import "firebase/auth";
import React from "react";
import { IoExitOutline } from "react-icons/io5";
import { DeleteButton } from "./DeleteButton";

export const Logout = (props) => {
  const { uid } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = firebase.auth();

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <Icon as={IoExitOutline} w={7} h={7} color="white" onClick={onOpen} />
      </div>

      <Modal size="xs" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Log Out</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button mr={5} colorScheme="blue" onClick={() => auth.signOut()}>
              Log Out
            </Button>

            <DeleteButton uid={uid} />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
