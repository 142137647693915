import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import "firebase/auth";
import "firebase/firestore";
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { colors } from "../helpers/colors";
import "../stylesheets/Drawer.css";
import { Tag } from "./Tag";

export const SmallScreenDrawer = (props) => {
  const { handleChangeDisplay } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChangeTab = (e, page) => {
    handleChangeDisplay(e, page);
    onClose();
  };

  return (
    <>
      <div className="Drawer-icon">
        <Icon as={GiHamburgerMenu} onClick={onOpen} color="gray.900" />
      </div>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen} size="xs">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px" color="gray.900">
              tea tracker
            </DrawerHeader>
            <DrawerBody>
              <div className="Drawer-tags d-flex flex-column justify-content-around">
                <Tag
                  label="At a Glance"
                  color={colors.peach}
                  size="lg"
                  onClick={(e) => handleChangeTab(e, "glance")}
                />
                <Tag
                  label="Tracker"
                  color={colors.brown}
                  size="lg"
                  onClick={(e) => handleChangeTab(e, "reviews")}
                />
                <Tag
                  label="Stats"
                  color={colors.darkBrown}
                  size="lg"
                  onClick={(e) => handleChangeTab(e, "summary")}
                />
              </div>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
