export const colors = {
  // Text
  black: "#50555c",

  // Stats
  darkBrown: "#666666",

  // Accents
  blue: "#93D4EF",
  green: "#C5DB97",
  budGreen: "#75b26c",
  aloeGreen: "#99c692",
  darkGreen: "57934D",
  pink: "#F5ACCB",
  apricot: "#FDC286",

  // At a Glance
  peach: "#FEDDBC",

  // Stars
  yellow: "#ffd700",

  // Feed
  slightlyDarkerBlue: "#2AA9DF",

  // Reviews
  brown: "#AB918D",
  slightlyDarkerBrown: "#8D6D68",
};
