import { Icon } from "@chakra-ui/react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { useRef } from "react";
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5";
import Loader from "react-loader-spinner";
import smoothscroll from "smoothscroll-polyfill";
import { colors } from "../helpers/colors";
import "../stylesheets/Reviews.css";
import { ReviewMessage } from "./ReviewMessage";

export const Reviews = (props) => {
  const auth = firebase.auth();
  const thisWeekReviewsRef = useRef(null);
  const lastWeekReviewsRef = useRef(null);
  const prevWeekReviewsRef = useRef(null);
  smoothscroll.polyfill();

  const {
    reviews,
    loadingReviews,
    setLoadingReviews,
    isAuthUser,
    isPublic,
  } = props;

  const filterReviews = (filter) => {
    const d = new Date();
    const dayOfWeek = d.getDay();
    const lastSunday = new Date().setDate(new Date().getDate() - dayOfWeek);
    const lastLastSunday = new Date().setDate(
      new Date().getDate() - dayOfWeek - 7
    );

    if (filter === "thisWeek") {
      return reviews.filter(
        (review) => review.createdAt.toDate() >= lastSunday
      );
    } else if (filter === "lastWeek") {
      return reviews.filter(
        (review) =>
          review.createdAt.toDate() >= lastLastSunday &&
          review.createdAt.toDate() < lastSunday
      );
    } else if (filter === "past") {
      return reviews.filter(
        (review) => review.createdAt.toDate() < lastLastSunday
      );
    } else {
      return reviews;
    }
  };

  if (loadingReviews) {
    return (
      <div className="Reviews-container d-flex flex-column align-items-center justify-content-center">
        <Loader
          type="ThreeDots"
          color={colors.slightlyDarkerBrown}
          height={80}
          width={80}
        />
      </div>
    );
  }

  if (!isPublic && !isAuthUser) {
    return (
      <div className="Reviews-container d-flex flex-column align-items-center justify-content-center text-center text-white">
        This profile is private.
      </div>
    );
  }

  if (reviews.length === 0) {
    return (
      <div className="Reviews-container d-flex flex-column align-items-center justify-content-center text-center text-white">
        No reviews to see here! Add a new review to get started.
      </div>
    );
  }

  const thisWeekReviews = filterReviews("thisWeek");
  const lastWeekReviews = filterReviews("lastWeek");
  const prevReviews = filterReviews("past");

  const scrollForward = (element) => {
    document.querySelector(element).scrollBy({ left: 320, behavior: "smooth" });
  };

  const scrollBackward = (element) => {
    document
      .querySelector(element)
      .scrollBy({ left: -320, behavior: "smooth" });
  };

  return (
    <div className="Reviews-container d-flex flex-column clearfix">
      {thisWeekReviews.length > 0 && (
        <div className="Reviews-sectionContainer d-flex flex-column">
          <div className="Reviews-containerHeader d-flex flex-row justify-content-between align-items-center text-bold text-white">
            <div>This Week</div>
            {thisWeekReviews.length > 1 && (
              <div className="d-flex flex-row">
                <div className="ml-1" style={{ cursor: "pointer" }}>
                  <Icon
                    as={IoChevronBackSharp}
                    w={5}
                    h={5}
                    color="white"
                    onClick={() => scrollBackward("#thisWeek")}
                  />
                </div>
                <div className="ml-3" style={{ cursor: "pointer" }}>
                  <Icon
                    as={IoChevronForwardSharp}
                    w={5}
                    h={5}
                    color="white"
                    onClick={() => scrollForward("#thisWeek")}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <div
              id="thisWeek"
              className="Reviews-reviewsContainer"
              ref={thisWeekReviewsRef}
            >
              {thisWeekReviews.map((review) => (
                <ReviewMessage
                  key={review.id}
                  review={review}
                  setLoadingReviews={setLoadingReviews}
                  isPublic={isPublic}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {lastWeekReviews.length > 0 && (
        <div className="Reviews-sectionContainer Reviews-sectionContainerMargin d-flex flex-column">
          <div className="Reviews-containerHeader d-flex flex-row justify-content-between align-items-center text-bold text-white">
            <div>Last Week</div>
            {lastWeekReviews.length > 1 && (
              <div className="d-flex flex-row">
                <div className="ml-1" style={{ cursor: "pointer" }}>
                  <Icon
                    as={IoChevronBackSharp}
                    w={5}
                    h={5}
                    color="white"
                    onClick={() => scrollBackward("#lastWeek")}
                  />
                </div>
                <div className="ml-3" style={{ cursor: "pointer" }}>
                  <Icon
                    as={IoChevronForwardSharp}
                    w={5}
                    h={5}
                    color="white"
                    onClick={() => scrollForward("#lastWeek")}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <div
              id="lastWeek"
              className="Reviews-reviewsContainer"
              ref={lastWeekReviewsRef}
            >
              {lastWeekReviews.map((review) => (
                <ReviewMessage
                  key={review.id}
                  review={review}
                  user={auth.currentUser}
                  setLoadingReviews={setLoadingReviews}
                  isPublic={isPublic}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {prevReviews.length > 0 && (
        <div className="Reviews-sectionContainer Reviews-sectionContainerMargin d-flex flex-column">
          <div className="Reviews-containerHeader d-flex flex-row justify-content-between align-items-center text-bold text-white">
            <div>More Reviews</div>
            {prevReviews.length > 1 && (
              <div className="d-flex flex-row">
                <div className="ml-1" style={{ cursor: "pointer" }}>
                  <Icon
                    as={IoChevronBackSharp}
                    w={5}
                    h={5}
                    color="white"
                    onClick={() => scrollBackward("#moreReviews")}
                  />
                </div>
                <div className="ml-3" style={{ cursor: "pointer" }}>
                  <Icon
                    as={IoChevronForwardSharp}
                    w={5}
                    h={5}
                    color="white"
                    onClick={() => scrollForward("#moreReviews")}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <div
              id="moreReviews"
              className="Reviews-reviewsContainer"
              ref={prevWeekReviewsRef}
            >
              {prevReviews.map((review) => (
                <ReviewMessage
                  key={review.id}
                  review={review}
                  user={auth.currentUser}
                  setLoadingReviews={setLoadingReviews}
                  isPublic={isPublic}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
