import React, { useState } from "react";
import { getUserWithUid } from "../helpers/helpers";
import { FriendCard } from "./FriendCard";

export const Followers = (props) => {
  const {
    followerList,
    followingList,
    setLoadingFollowingList,
    loadingFollowingList,
    loadingFollowerList,
    loader,
    loadingUserSettings,
    setLoadingUserSettings,
    setLoadingFriendReviews,
  } = props;

  const [followerUsers, setFollowerUsers] = useState([]);
  const [loadingFollowerUsers, setLoadingFollowerUsers] = useState(true);

  React.useEffect(() => {
    const fetchFollowingUsers = async () => {
      const newFollowerUsers = [];
      if (loadingFollowerUsers && !loadingFollowerList) {
        for (const follower of followerList) {
          const user = await getUserWithUid(follower.follower);
          newFollowerUsers.push({ ...user, uid: follower.follower });
        }
        setFollowerUsers(newFollowerUsers);
        setLoadingFollowerUsers(false);
      }
    };
    fetchFollowingUsers();
  }, [followerList, loadingFollowerList, loadingFollowerUsers]);

  if (loadingFollowerUsers || loadingFollowerList || loadingUserSettings) {
    return loader;
  } else if (followerUsers.length === 0) {
    return (
      <div className="Feed-container d-flex flex-column align-items-center justify-content-center text-center text-white">
        No followers yet!
      </div>
    );
  } else {
    return (
      <div className="Feed-container d-flex flex-column clearfix">
        <div className="Feed-containerHeader text-bold text-white">{`${followerUsers.length} Followers`}</div>
        {!loadingFollowerUsers &&
          !loadingFollowerList &&
          !loadingUserSettings &&
          followerUsers.map((user, i) => {
            return (
              <FriendCard
                key={`FriendCard-${i}`}
                user={user}
                setLoadingUserSettings={setLoadingUserSettings}
                userFollowingList={followingList}
                setLoadingFollowingList={setLoadingFollowingList}
                loadingFollowingList={loadingFollowingList}
                setLoadingFriendReviews={setLoadingFriendReviews}
                feed={true}
              />
            );
          })}
      </div>
    );
  }
};
