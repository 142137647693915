import blackmtb from "../assets/black_milk_tea_black.png";
import blackmtw from "../assets/black_milk_tea_white.png";
import jasminemtb from "../assets/jasmine_milk_tea_black.png";
import jasminemtw from "../assets/jasmine_milk_tea_white.png";
import mungbeanb from "../assets/mung_bean_black.png";
import mungbeanw from "../assets/mung_bean_white.png";
import redteab from "../assets/red_tea_black.png";
import redteaw from "../assets/red_tea_white.png";
import taromtb from "../assets/taro_milk_tea_black.png";
import taromtw from "../assets/taro_milk_tea_white.png";
import thaiteab from "../assets/thai_tea_black.png";
import thaiteaw from "../assets/thai_tea_white.png";
import yellowteab from "../assets/yellow_tea_black.png";
import yellowteaw from "../assets/yellow_tea_white.png";

export const bobaDrinks = {
  blackmt: {
    imageBlack: blackmtb,
    imageWhite: blackmtw,
    keywords: ["black"],
  },
  jasminemt: {
    imageBlack: jasminemtw,
    imageWhite: jasminemtb,
    keywords: ["jasmine"],
  },
  mungbean: {
    imageBlack: mungbeanb,
    imageWhite: mungbeanw,
    keywords: ["mung", "matcha", "green", "honeydew", "kiwi"],
  },
  redtea: {
    imageBlack: redteab,
    imageWhite: redteaw,
    keywords: [
      "strawberry",
      "cherry",
      "rose",
      "raspberry",
      "grapefruit",
      "watermelon",
      "cranberry",
    ],
  },
  taromt: {
    imageBlack: taromtb,
    imageWhite: taromtw,
    keywords: ["taro", "grape"],
  },
  thaiteab: {
    imageBlack: thaiteab,
    imageWhite: thaiteaw,
    keywords: ["thai", "orange", "mandarin", "clementine"],
  },
  yellowtea: {
    imageBlack: yellowteab,
    imageWhite: yellowteaw,
    keywords: [
      "lemon",
      "pineapple",
      "peach",
      "mango",
      "passion fruit",
      "fruit",
    ],
  },
};
