import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import "firebase/auth";
import "firebase/firestore";
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { colors } from "../helpers/colors";
import "../stylesheets/Drawer.css";
import { Tag } from "./Tag";

export const SmallScreenFeedDrawer = (props) => {
  const { handleChangeDisplay, display } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChangeTab = (e, page) => {
    handleChangeDisplay(e, page);
    onClose();
  };

  return (
    <>
      <div className="Drawer-icon">
        <Icon as={GiHamburgerMenu} onClick={onOpen} color="gray.900" />
      </div>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen} size="xs">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px" color="gray.900">
              tea tracker
            </DrawerHeader>
            <DrawerBody>
              <div className="Drawer-tags d-flex flex-column justify-content-around">
                <Tag
                  label="Feed"
                  color={colors.blue}
                  size="lg"
                  outline={display === "feed" ? false : true}
                  onClick={(e) => handleChangeTab(e, "feed")}
                />
                <Tag
                  label="Following"
                  color={colors.blue}
                  size="lg"
                  outline={display === "following" ? false : true}
                  onClick={(e) => handleChangeTab(e, "following")}
                />
                <Tag
                  label="Followers"
                  color={colors.blue}
                  size="lg"
                  outline={display === "followers" ? false : true}
                  onClick={(e) => handleChangeTab(e, "followers")}
                />
              </div>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
