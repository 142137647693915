import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { colors } from "../helpers/colors";
import { getUsername } from "../helpers/helpers";
import "../stylesheets/FollowerCard.css";

export const FollowerCard = (props) => {
  const { uid } = props;
  const [username, setUsername] = useState("");
  const [loadingUsername, setLoadingUsername] = useState(true);

  React.useEffect(() => {
    if (loadingUsername) {
      const fetchUsername = async () => {
        const uname = await getUsername(uid);
        setUsername(uname);
        setLoadingUsername(false);
      };
      fetchUsername();
    }
  }, [loadingUsername, uid]);

  return (
    <div className="FollowerCard d-flex flex-column justify-content-center align-content-center">
      {!loadingUsername ? (
        <div className="d-flex justify-content-center">
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: `/user/${username}`,
              state: {
                fromFeed: true,
              },
            }}
          >
            <div className="FollowerCard-name text-bold">{`@${username} `}</div>
          </Link>
          <div className="text-normal">followed you!</div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <Loader
            type="ThreeDots"
            color={colors.budGreen}
            height={15}
            width={15}
          />
        </div>
      )}
    </div>
  );
};
