import { Icon } from "@chakra-ui/react";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import ReactStars from "react-rating-stars-component";
import { colors } from "../helpers/colors";
import "../stylesheets/SummaryItem.css";

export const SummaryItem = (props) => {
  const { primaryStat, lines, animation, color } = props;

  return (
    <div className="SummaryItem d-flex flex-column justify-content-center align-items-center">
      {lines.map((line) => {
        if (line.type === "image") {
          return (
            <ScrollAnimation
              key={`ScrollAnimation-${line.item}`}
              duration={2}
              animateIn={animation}
              scrollableParentSelector="#scrolly-div"
            >
              <img style={{ height: 100 }} src={line.item} alt="summary" />
            </ScrollAnimation>
          );
        } else if (line.type === "largeText") {
          return (
            <ScrollAnimation
              key={`ScrollAnimation-${line.item}`}
              duration={2}
              animateIn={animation}
              scrollableParentSelector="#scrolly-div"
            >
              <div
                style={{ color: color || "white" }}
                className="SummaryItem-largeText text-bold text-center"
              >
                {typeof line.item === "string"
                  ? line.item.toUpperCase()
                  : line.item}
              </div>
            </ScrollAnimation>
          );
        } else if (line.type === "rating") {
          return (
            <ScrollAnimation
              key={`ScrollAnimation-${line.item}`}
              duration={2}
              animateIn={animation}
              scrollableParentSelector="#scrolly-div"
            >
              <ReactStars
                count={5}
                size={24}
                value={primaryStat}
                isHalf={true}
                activeColor={colors.yellow}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                edit={false}
              />
            </ScrollAnimation>
          );
        } else if (line.type === "icon") {
          return (
            <ScrollAnimation
              key={`ScrollAnimation-${line.item}`}
              duration={2}
              animateIn={animation}
              scrollableParentSelector="#scrolly-div"
            >
              <Icon
                as={line.item}
                h={20}
                w={20}
                m={5}
                color={color || "white"}
              />
            </ScrollAnimation>
          );
        } else {
          return (
            <div
              key={line.item.toLowerCase()}
              className="SummaryItem-text text-bold text-center text-white"
            >
              {line.item.toUpperCase()}
            </div>
          );
        }
      })}
    </div>
  );
};
