import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { colors } from "../helpers/colors";
import { getUser, getUserWithUsername } from "../helpers/helpers";
import "../stylesheets/Main.css";
import "../stylesheets/Reviews.css";
import "../stylesheets/Root.css";
import { Feed } from "./Feed";
import { Footer } from "./Footer";
import { Main } from "./Main";
import { NotFoundAuth } from "./NotFoundAuth";

export const Root = (props) => {
  const [userSettings, setUserSettings] = useState({
    username: "",
    location: "",
    displayName: "",
    photoUrl: "",
    public: false,
    following: [],
  });
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const [reviews, setReviews] = useState([]);
  const [loadingReviews, setLoadingReviews] = useState(true);
  const [profileUserSettings, setProfileUserSettings] = useState({
    username: "",
    location: "",
    displayName: "",
    photoUrl: "",
    public: false,
  });
  const [loadingUserSettings, setLoadingUserSettings] = useState(true);
  const [profileReviews, setProfileReviews] = useState([]);
  const [loadingProfileReviews, setLoadingProfileReviews] = useState(true);
  const [loadingProfileUserSettings, setLoadingProfileUserSettings] = useState(
    true
  );
  // profileUser refers to the user whose username is coming through the URL.
  const [profileUser, setProfileUser] = useState({});
  const [loadingProfileUser, setLoadingProfileUser] = useState(true);
  const location = useLocation();

  // If the profileUser is the authenticated user, get reviews for this user
  // with auth capabilities. Otherwise, show them reviews for the retrieved
  // profileUser.

  const reviewsRef = firestore.collection("reviews");

  const fromFeed = props.fromFeed;

  // Load the user profile being searched.
  React.useEffect(() => {
    if (loadingProfileUser && props.profileUsername) {
      const fetchProfileUser = async () => {
        const user = await getUserWithUsername(props.profileUsername);
        if (user && user.id !== auth.currentUser.uid) {
          setProfileUser(user);
          setLoadingProfileUser(false);
        } else {
          setLoadingProfileUser(false);
        }
      };

      fetchProfileUser();
    } else if (!props.profileUsername) {
      setLoadingProfileUser(false);
    }
  }, [auth.currentUser.uid, loadingProfileUser, props.profileUsername]);

  // Load the authenticated user's reviews.
  React.useEffect(() => {
    if (loadingReviews) {
      const fetchReviews = async () => {
        const snapshots = await reviewsRef
          .where("userId", "==", auth.currentUser.uid)
          .orderBy("createdAt", "desc")
          .get();
        if (snapshots.empty) {
          setReviews([]);
          setLoadingReviews(false);
          return;
        }
        const allReviews = [];
        snapshots.forEach((doc) => {
          allReviews.push({ ...doc.data(), id: doc.id });
        });
        setReviews(allReviews);
        setLoadingReviews(false);
      };

      fetchReviews();
    }
  }, [auth.currentUser.uid, reviewsRef, setLoadingReviews, loadingReviews]);

  // Loading the authenticated user's settings.
  React.useEffect(() => {
    if (loadingUserSettings) {
      const fetchData = async () => {
        const user = await getUser(auth.currentUser);
        const username = user.username;
        const location = user.location;
        const displayName = user.displayName;
        const photoUrl = auth.currentUser.photoURL;
        const isPublic = user.public;
        const following = user.following;
        setUserSettings({
          username,
          location,
          displayName,
          photoUrl,
          public: isPublic,
          following,
        });
        setLoadingUserSettings(false);
      };

      fetchData();
    }
  }, [auth.currentUser, userSettings, loadingUserSettings, profileUser.uid]);

  // Load the public user profile's reviews if there is a public profile user object.
  // Don't do any loading if it's a private user.
  React.useEffect(() => {
    if (
      !loadingProfileUser &&
      Object.keys(profileUser).length !== 0 &&
      profileUser.data.public &&
      loadingProfileReviews
    ) {
      const fetchReviews = async () => {
        const snapshots = await reviewsRef
          .where("userId", "==", profileUser.uid)
          .orderBy("createdAt", "desc")
          .get();
        if (snapshots.empty) {
          setProfileReviews([]);
          setLoadingProfileReviews(false);
          return;
        }
        const allReviews = [];
        snapshots.forEach((doc) => {
          allReviews.push({ ...doc.data(), id: doc.id });
        });
        setProfileReviews(allReviews);
        setLoadingProfileReviews(false);
      };

      fetchReviews();
    } else if (!loadingProfileUser && Object.keys(profileUser).length !== 0) {
      setLoadingProfileReviews(false);
    }
  }, [loadingProfileReviews, profileUser, loadingProfileUser, reviewsRef]);

  // Load the profile user's settings if there is a profile user object.
  React.useEffect(() => {
    if (
      !loadingProfileUser &&
      Object.keys(profileUser).length !== 0 &&
      loadingProfileUserSettings
    ) {
      const fetchData = async () => {
        const user = await getUser(profileUser);
        const username = user.username;
        const location = user.location;
        const displayName = user.displayName;
        const photoUrl = user.photoUrl;
        const isPublic = user.public;
        setProfileUserSettings({
          username,
          location,
          displayName,
          photoUrl,
          public: isPublic,
        });
        setLoadingProfileUserSettings(false);
      };

      fetchData();
    } else if (!loadingProfileUser && Object.keys(profileUser).length !== 0) {
      setLoadingProfileUserSettings(false);
    }
  }, [
    loadingProfileUserSettings,
    auth.currentUser,
    loadingProfileUser,
    profileUser,
  ]);

  // Display not found page if we couldn't find a user with that username.
  if (
    !loadingProfileUser &&
    Object.keys(profileUser).length === 0 &&
    location.pathname !== "/feed" &&
    location.pathname !== "/"
  ) {
    return <NotFoundAuth />;
  }

  return (
    <div className="Root-page">
      {location.pathname === "/feed" ? (
        <Feed
          setLoadingUserSettings={setLoadingUserSettings}
          loadingUserSettings={loadingUserSettings}
        />
      ) : !loadingProfileUser &&
        Object.keys(profileUser).length !== 0 &&
        profileUser.uid !== auth.currentUser.uid ? (
        // Load a profile. Can be public or private.
        <Main
          reviews={profileReviews}
          loadingReviews={loadingProfileReviews}
          setLoadingReviews={setLoadingProfileReviews}
          userSettings={profileUserSettings}
          loadingUserSettings={loadingProfileUserSettings}
          authUserSettings={userSettings}
          setAuthLoadingUserSettings={setLoadingUserSettings}
          profileUserId={profileUser.uid}
          isPublic={profileUser.data.public}
          fromFeed={fromFeed}
        />
      ) : location.pathname === "/" ||
        (!loadingProfileUser &&
          !loadingUserSettings &&
          profileUser.uid === auth.currentUser.uid) ? (
        // Display auth user's own profile.
        <Main
          reviews={reviews}
          loadingReviews={loadingReviews}
          setLoadingReviews={setLoadingReviews}
          userSettings={userSettings}
          loadingUserSettings={loadingUserSettings}
          isAuthUser={true}
          fromFeed={fromFeed}
        />
      ) : (
        <div className="Main-page d-flex align-items-center justify-content-center">
          <Loader type="ThreeDots" color={colors.blue} height={80} width={80} />
        </div>
      )}
      <Footer
        location={location.pathname}
        setLoadingReviews={setLoadingReviews}
        loadingUserSettings={loadingUserSettings}
        userSettings={userSettings}
        setUserSettings={setUserSettings}
      />
    </div>
  );
};
