import Loader from "react-loader-spinner";
import jasmine from "../assets/jasmine_milk_tea_black.png";
import mung from "../assets/mung_bean_black.png";
import taro from "../assets/taro_milk_tea_black.png";
import thai from "../assets/thai_tea_black.png";
import { colors } from "../helpers/colors";
import { getFirstOfMonth } from "../helpers/helpers";
import {
  computeFavoriteItemSummaries,
  computeFavoriteTopping,
  computeTotalOrders,
  computeTotalSpent,
} from "../helpers/summaryHelpers";
import "../stylesheets/Glance.css";
import { Calendar } from "./Calendar";
import { GlanceCard } from "./GlanceCard";

export const Glance = (props) => {
  const { reviews, loadingReviews, isPublic, isAuthUser } = props;

  if (loadingReviews) {
    return (
      <div className="Glance-container d-flex flex-column align-items-center justify-content-center">
        <Loader
          type="ThreeDots"
          color={colors.apricot}
          height={80}
          width={80}
        />
      </div>
    );
  }

  const getCreatedAtList = () => {
    // {31: ["Pearl Milk Tea", "Fruit Tea"]}
    const createdAtToDrinkName = {};
    const firstOfMonth = getFirstOfMonth();
    const filteredReviews = reviews.filter(
      (review) => review.createdAt.toDate() >= firstOfMonth
    );
    filteredReviews.forEach((review) => {
      if ("createdAt" in review) {
        const date = review.createdAt.toDate().getDate();
        if (date in createdAtToDrinkName) {
          createdAtToDrinkName[date].push(review.drinkName);
        } else {
          createdAtToDrinkName[date] = [review.drinkName];
        }
      }
    });
    return createdAtToDrinkName;
  };

  var firstDay = getFirstOfMonth();
  const thisMonthReviews = reviews.filter(
    (review) => review.createdAt.toDate() >= firstDay
  );

  const totalSpent = computeTotalSpent(thisMonthReviews);
  const totalOrders = computeTotalOrders(thisMonthReviews);
  const favoriteTopping = computeFavoriteTopping(thisMonthReviews);
  const favoriteDrink = computeFavoriteItemSummaries(thisMonthReviews)[
    "drinkName"
  ].favorite;

  if (!isPublic && !isAuthUser) {
    return (
      <div className="Glance-container d-flex flex-column align-items-center justify-content-center text-center text-white">
        This profile is private.
      </div>
    );
  }

  return (
    <div className="Glance-container d-flex flex-column clearfix">
      <div className="Glance-containerItem">
        <Calendar createdAtList={getCreatedAtList()} />
      </div>
      <div className="Glance-cardContainer d-flex flex-column flex-shrink-0">
        <div className="Glance-header text-bold">
          {isPublic ? "Their" : "Your"} Monthly Boba Fix
        </div>
        <div className="Glance-card d-flex flex-row justify-content-between flex-shrink-0">
          <GlanceCard
            label={isPublic ? "They spent" : "You spent"}
            stat={totalSpent ? `$${totalSpent}` : "$0.00"}
            image={jasmine}
            highlightColor={colors.pink}
            size="sm"
          />
          <GlanceCard
            label={isPublic ? "They drank" : "You drank"}
            stat={
              totalOrders
                ? `${totalOrders} ${totalOrders === 1 ? "boba" : "bobas"}`
                : "0 bobas"
            }
            image={taro}
            highlightColor={colors.blue}
            size="sm"
            isDrinkCount={true}
          />
        </div>
        <GlanceCard
          label="Favorite topping"
          stat={favoriteTopping}
          image={thai}
          size="lg"
          highlightColor={colors.apricot}
          tag={true}
        />
        <GlanceCard
          label="Favorite drink"
          stat={favoriteDrink}
          image={mung}
          highlightColor={colors.aloeGreen}
          size="lg"
        />
      </div>
    </div>
  );
};
