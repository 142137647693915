import { Icon } from "@chakra-ui/react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import bg from "../assets/bg1.png";
import boba from "../assets/taro_milk_tea_black.png";
import { colors } from "../helpers/colors";
import { truncate } from "../helpers/helpers";
import "../stylesheets/Main.css";
import "../stylesheets/Reviews.css";
import { SmallScreenDrawer } from "./Drawer";
import { Follow } from "./Follow";
import { Glance } from "./Glance";
import { Reviews } from "./Reviews";
import { Summary } from "./Summary";
import { Tag } from "./Tag";

export const Main = (props) => {
  const {
    reviews,
    loadingReviews,
    setLoadingReviews,
    userSettings,
    loadingUserSettings,
    setLoadingUserSettings,
    authUserSettings,
    setAuthLoadingUserSettings,
    isPublic,
    profileUserId,
    fromFeed,
    isAuthUser,
  } = props;

  const auth = firebase.auth();
  const [display, setDisplay] = useState("glance");

  function handleChangeDisplay(e, displayType) {
    e.preventDefault();
    if (display !== displayType) {
      setDisplay(displayType);
      setLoadingReviews(true);
    }
  }

  const matches = useMediaQuery("(min-width:479px)");
  const smallPhone = useMediaQuery("(max-height:680px)");

  return (
    <div
      style={{
        backgroundImage: matches ? `url(${bg})` : "",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className="Main-page d-flex flex-column justify-content-between align-items-center clearfix"
    >
      {smallPhone && (
        <SmallScreenDrawer handleChangeDisplay={handleChangeDisplay} />
      )}
      {fromFeed && (
        <Link to="/feed">
          <div className="Main-back">
            <Icon as={IoChevronBack} w={7} h={7} color="gray.900" />
          </div>
        </Link>
      )}
      <div>
        <div>
          {loadingUserSettings ? (
            <div
              style={{ width: 200, height: 95 }}
              className="d-flex justify-content-center align-items-center ml-2"
            >
              <Loader
                type="ThreeDots"
                color={colors.apricot}
                height={40}
                width={40}
              />
            </div>
          ) : (
            <div className="Main-header d-flex flex-row text-center align-items-center justify-content-between p-3">
              <img
                className={
                  userSettings.photoUrl ? "Main-avatar" : "Main-avatarNoBorder"
                }
                rel="noreferrer"
                referrerPolicy="origin"
                alt={userSettings.displayName || "User Avatar"}
                src={userSettings.photoUrl || boba}
              />
              <div className="Main-userContainer d-flex flex-column justify-content-center align-items-start text-black ml-2">
                <div className="Main-name text-bold text-wrap text-left">
                  {userSettings.displayName !== auth.currentUser.displayName
                    ? userSettings.displayName.split(" ")[0]
                    : `Hello, ${truncate(
                        userSettings.displayName.split(" ")[0],
                        9
                      )}!`}
                </div>
                <div className="Main-username text-bold text-left">
                  @{userSettings.username.toUpperCase()}
                  {userSettings.location &&
                    ` | ${userSettings.location.toUpperCase()}`}
                </div>
                {isPublic && (
                  <Follow
                    targetUserId={profileUserId}
                    setLoadingUserSettings={setAuthLoadingUserSettings}
                    currFollowingList={authUserSettings.following}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {!smallPhone && (
        <div className="Main-tabs d-flex flex-row justify-content-between">
          <Tag
            label="At a Glance"
            color={colors.peach}
            size="lg"
            onClick={(e) => handleChangeDisplay(e, "glance")}
          />
          <Tag
            label="Reviews"
            color={colors.brown}
            size="lg"
            onClick={(e) => handleChangeDisplay(e, "reviews")}
          />
          <Tag
            label="Stats"
            color={colors.darkBrown}
            size="lg"
            onClick={(e) => handleChangeDisplay(e, "summary")}
          />
        </div>
      )}
      {display === "reviews" && (
        <Reviews
          reviews={reviews}
          loadingReviews={loadingReviews}
          setLoadingReviews={setLoadingReviews}
          isPublic={isPublic}
          isAuthUser={isAuthUser}
          setLoadingUserSettings={setLoadingUserSettings}
        />
      )}
      {display === "summary" && (
        <Summary
          reviews={reviews}
          loadingReviews={loadingReviews}
          isPublic={isPublic}
          isAuthUser={isAuthUser}
        />
      )}
      {display === "glance" && (
        <Glance
          reviews={reviews}
          loadingReviews={loadingReviews}
          isPublic={isPublic}
          isAuthUser={isAuthUser}
        />
      )}
    </div>
  );
};
