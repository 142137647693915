import React, { useState } from "react";
import Loader from "react-loader-spinner";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { DeleteReviewMessageButton } from "../components/DeleteReview";
import { colors } from "../helpers/colors";
import { getDisplayName, getUsername, truncate } from "../helpers/helpers";
import "../stylesheets/ReviewMessage.css";
import summaryJson from "../summary.json";
import { Tag } from "./Tag";

export const ReviewMessage = (props) => {
  const {
    reviewText,
    rating,
    createdAt,
    drinkName,
    price,
    storeName,
    topping1,
    topping2,
    topping3,
    id,
    userId,
  } = props.review;
  const { setLoadingReviews, isPublic, feed = false } = props;
  const [displayName, setDisplayName] = useState("");
  const [loadingDisplayName, setLoadingDisplayName] = useState(true);
  const [profileUsername, setProfileUsername] = useState("");
  const [loadingProfileUsername, setLoadingProfileUsername] = useState(true);

  React.useEffect(() => {
    if (loadingDisplayName) {
      const fetchDisplayName = async () => {
        const name = await getDisplayName(userId);
        setDisplayName(name);
        setLoadingDisplayName(false);
      };
      fetchDisplayName();
    }
  }, [loadingDisplayName, userId]);

  React.useEffect(() => {
    if (loadingProfileUsername) {
      const fetchUsername = async () => {
        const username = await getUsername(userId);
        setProfileUsername(username);
        setLoadingProfileUsername(false);
      };
      fetchUsername();
    }
  }, [loadingProfileUsername, userId]);

  const truncateLength = feed && !loadingProfileUsername ? 25 : 30;
  const drinkTruncated = truncate(drinkName, truncateLength).toUpperCase();
  const storeTruncated = truncate(
    storeName,
    40 - drinkTruncated.length
  ).toUpperCase();

  const reviewTitle = `${drinkTruncated} from 
${storeTruncated}`;

  if (loadingDisplayName) {
    return (
      <div
        style={{ height: "100%", maxHeight: 220 }}
        className="ReviewMessage-message d-flex flex-column justify-content-center align-items-center"
      >
        <Loader
          type="ThreeDots"
          color={feed ? colors.blue : colors.brown}
          height={40}
          width={40}
        />
      </div>
    );
  }

  return (
    <div className="ReviewMessage-message d-flex flex-column clearfix">
      <div className="d-flex justify-content-between align-items-center">
        <div className="ReviewMessage-date text-bold">
          {createdAt && createdAt.toDate().toDateString().toUpperCase()}
        </div>
        {!isPublic && id && (
          <DeleteReviewMessageButton
            docId={id}
            setLoadingReviews={setLoadingReviews}
          />
        )}
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center py-1">
        <ReactStars
          count={5}
          size={24}
          value={rating}
          activeColor={colors.yellow}
          emptyIcon={<i className="far fa-star"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          edit={false}
          isHalf={true}
        />
        {price >= 0 && (
          <div className="ReviewMessage-price text-bold">
            ${price.toFixed(2)}
          </div>
        )}
      </div>
      <div className="ReviewMessage-break d-flex flex-row text-break">
        <div className="ReviewMessage-drinkName text-bold">
          {feed && !loadingProfileUsername && (
            <span className="ReviewMessage-userPhrase">
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: `/user/${profileUsername}`,
                  state: {
                    fromFeed: true,
                  },
                }}
              >
                <span className="ReviewMessage-userDisplayName">
                  {displayName.split(" ")[0]}
                </span>
              </Link>
              <span> got </span>
            </span>
          )}
          <span>{reviewTitle}</span>
        </div>
      </div>
      <div
        className="d-flex flex-row justify-content-start"
        style={{ marginBottom: 16 }}
      >
        {topping1 && (
          <div style={{ marginRight: 6 }}>
            <Tag
              size="sm"
              color={summaryJson.toppings.hex[topping1.toLowerCase()]}
              label={topping1.toUpperCase()}
            />
          </div>
        )}
        {topping2 && (
          <div style={{ marginRight: 6 }}>
            <Tag
              size="sm"
              color={summaryJson.toppings.hex[topping2.toLowerCase()]}
              label={topping2.toUpperCase()}
              style={{ marginLeft: 6, marginRight: 6 }}
            />
          </div>
        )}
        {topping3 && (
          <Tag
            size="sm"
            color={summaryJson.toppings.hex[topping3.toLowerCase()]}
            label={topping3.toUpperCase()}
          />
        )}
      </div>
      {reviewText && (
        <div className="ReviewMessage-text text-medium">
          {truncate(reviewText, 91)}
        </div>
      )}
    </div>
  );
};
