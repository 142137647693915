import { getLastSunday } from "../helpers/helpers";
import { FollowerCard } from "./FollowerCard";
import { ReviewMessage } from "./ReviewMessage";

export const FriendReviews = (props) => {
  const {
    loadingFollowingList,
    loadingFriendReviews,
    loadingFollowerNotifs,
    loader,
    friendReviews,
    followerNotifs,
  } = props;

  if (loadingFollowingList || loadingFriendReviews || loadingFollowerNotifs) {
    return loader;
  }

  if (Object.keys(friendReviews).length === 0 && followerNotifs.length === 0) {
    return (
      <div className="Feed-container d-flex flex-column align-items-center justify-content-center text-center text-white">
        No reviews this week!
      </div>
    );
  }

  const combineAndSort = () => {
    const lastSunday = getLastSunday();
    const filteredNotifs = followerNotifs.filter(
      (notif) => notif.createdAt.toDate() >= lastSunday
    );
    const reviewsAndFollowers = friendReviews.concat(filteredNotifs);
    reviewsAndFollowers.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    return reviewsAndFollowers;
  };

  return (
    <div className="Feed-container d-flex flex-column clearfix">
      <div className="Feed-containerHeader text-bold text-white">This Week</div>
      <div className="Feed-reviewsContainer">
        {!loadingFollowerNotifs &&
          !loadingFriendReviews &&
          combineAndSort().map((item, i) => {
            if ("follower" in item) {
              return <FollowerCard key={`Follower-${i}`} uid={item.follower} />;
            } else {
              return (
                <ReviewMessage
                  key={item.id}
                  review={item}
                  isPublic={true}
                  feed={true}
                />
              );
            }
          })}
      </div>
    </div>
  );
};
