import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import firebase from "firebase/app";
import "firebase/auth";
import React from "react";
import { deleteUser } from "../helpers/helpers";

export const DeleteButton = (props) => {
  const { uid } = props;
  const initialFocusRef = React.useRef();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const auth = firebase.auth();

  const handleDeleteAndLogout = async () => {
    await deleteUser(uid);
    auth.signOut();
  };

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement="bottom"
      isOpen={isOpen}
      onOpen={onOpen}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button colorScheme="red">Delete Account</Button>
      </PopoverTrigger>
      <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Are you sure you want to delete your account?
        </PopoverHeader>
        <PopoverArrow />
        <PopoverBody>
          All data related to you, including your boba reviews, will be deleted
          from our database.
        </PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button colorScheme="teal" ref={initialFocusRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={async () => handleDeleteAndLogout()}
            >
              Delete My Account
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
