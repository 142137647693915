import { SettingsIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";
import firebase from "firebase/app";
import "firebase/auth";
import { IoHomeSharp, IoPeopleSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import "../stylesheets/Footer.css";
import { Logout } from "./Logout";
import { NewReview } from "./NewReview";
import { Settings } from "./Settings";

export const Footer = (props) => {
  const auth = firebase.auth();

  const {
    setLoadingReviews,
    location,
    userSettings,
    setUserSettings,
    loadingUserSettings,
  } = props;

  return (
    <div className="Footer-container d-flex flex-row align-items-center justify-content-around">
      <Link to="/">
        <Icon
          as={IoHomeSharp}
          w={7}
          h={7}
          color={location === "/" ? "blue.50" : "white"}
        />
      </Link>
      <Link to="/feed">
        <Icon
          as={IoPeopleSharp}
          w={7}
          h={7}
          color={location === "/feed" ? "blue.50" : "white"}
        />
      </Link>
      <NewReview setLoadingReviews={setLoadingReviews} />
      {loadingUserSettings ? (
        <SettingsIcon w={7} h={7} color="white" />
      ) : (
        <Settings
          uid={auth.currentUser.uid}
          userSettings={userSettings}
          setUserSettings={setUserSettings}
        />
      )}
      <Logout uid={auth.currentUser.uid} />
    </div>
  );
};
