import { Icon } from "@chakra-ui/react";
import "firebase/auth";
import "firebase/firestore";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoHandRight } from "react-icons/io5";
import { RiBubbleChartFill } from "react-icons/ri";
import Loader from "react-loader-spinner";
import boba from "../assets/jasmine_milk_tea_white.png";
import { SummaryItem } from "../components/SummaryItem";
import { bobaDrinks } from "../helpers/bobaDrinks";
import { colors } from "../helpers/colors";
import { MONTHS } from "../helpers/helpers";
import {
  computeAverageRating,
  computeFavoriteItemSummaries,
  computeFavoriteTopping,
  computeTotalOrders,
  computeTotalSpent,
} from "../helpers/summaryHelpers";
import "../stylesheets/Summary.css";
import summaryJson from "../summary.json";

export const Summary = (props) => {
  const { reviews, loadingReviews, isPublic, isAuthUser } = props;

  // All statistics are cumulative from the point the profile was created.
  const computeSummary = () => {
    const totalSpent = computeTotalSpent(reviews);
    const averageRating = computeAverageRating(reviews);
    const totalAmtReviews = computeTotalOrders(reviews);
    const favoriteSummaries = computeFavoriteItemSummaries(reviews);
    const favoriteDrink = favoriteSummaries["drinkName"].favorite;
    const favoriteStore = favoriteSummaries["storeName"].favorite;
    const favoriteTopping = computeFavoriteTopping(reviews);

    return {
      favoriteDrink,
      favoriteStore,
      totalSpent,
      averageRating,
      totalAmtReviews,
      favoriteTopping,
    };
  };

  const getBobaImg = (drink) => {
    const words = drink.split(" ");
    for (var i = 0; i < words.length; i++) {
      const lowercaseDrink = words[i].toLowerCase();
      for (var j = 0; j < Object.keys(bobaDrinks).length; j++) {
        const bobaType = bobaDrinks[Object.keys(bobaDrinks)[j]];
        if (bobaType.keywords.indexOf(lowercaseDrink) > -1) {
          return bobaType.imageWhite;
        }
      }
    }
    return boba;
  };

  const summary = reviews ? computeSummary() : {};
  const d = new Date();
  const month = d.getMonth();
  const day = d.getDate();

  if (loadingReviews) {
    return (
      <div className="Summary-container d-flex flex-column align-items-center justify-content-center">
        <Loader
          type="ThreeDots"
          color={colors.darkBrown}
          height={80}
          width={80}
        />
      </div>
    );
  }

  if (!isPublic && !isAuthUser) {
    return (
      <div className="Summary-container d-flex flex-column align-items-center justify-content-center text-center text-white">
        This profile is private.
      </div>
    );
  }

  if (reviews.length === 0) {
    return (
      <div className="Summary-container d-flex flex-column align-items-center justify-content-center text-center text-white">
        Add more reviews to see your stats!
      </div>
    );
  }

  return (
    <div
      id="scrolly-div"
      className="Summary-container d-flex flex-column align-items-center clearfix"
    >
      <div className="SummaryItem-date text-normal text-white">
        As of {MONTHS[month].full} {day}
      </div>
      {summary.totalAmtReviews && (
        <div>
          <SummaryItem
            id="totalAmtReviews"
            animation="tada"
            summaryName="totalAmtReviews"
            color={colors.blue}
            primaryStat={summary.totalAmtReviews}
            lines={[
              {
                type: "text",
                item: isPublic ? "They drank..." : "You drank...",
              },
              {
                type: "largeText",
                item: summary.totalAmtReviews,
              },
              {
                type: "text",
                item: summary.totalAmtReviews === 1 ? "boba" : "bobas",
              },
            ]}
          />
          <div className="mt-3 mb-3">
            <ScrollAnimation
              key={`ScrollAnimation-summaryTransition-4`}
              duration={1}
              animateIn={"fadeInDown"}
              scrollableParentSelector="#scrolly-div"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Icon
                  as={BsThreeDotsVertical}
                  h={10}
                  w={10}
                  m={5}
                  color={"white"}
                />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      )}
      {summary.totalSpent && (
        <div>
          <SummaryItem
            id="totalSpent"
            animation="bounceIn"
            summaryName="totalSpent"
            color={colors.pink}
            primaryStat={summary.totalSpent}
            lines={[
              {
                type: "text",
                item: isPublic ? "They spent..." : "You spent...",
              },
              {
                type: "largeText",
                item: `$${summary.totalSpent.toString()}`,
              },
              {
                type: "text",
                item: "on boba this year",
              },
            ]}
          />
          <div className="mt-3 mb-3">
            <ScrollAnimation
              key={`ScrollAnimation-summaryTransition-4`}
              duration={1}
              animateIn={"fadeInDown"}
              scrollableParentSelector="#scrolly-div"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Icon
                  as={BsThreeDotsVertical}
                  h={10}
                  w={10}
                  m={5}
                  color={"white"}
                />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      )}
      {summary.averageRating && (
        <div>
          <SummaryItem
            id="averageRating"
            animation="fadeIn"
            summaryName="averageRating"
            primaryStat={summary.averageRating}
            lines={[
              {
                type: "text",
                item: isPublic
                  ? "On average, they rated their drinks..."
                  : "On average, you rated your drinks...",
              },
              {
                type: "rating",
                item: summary.averageRating,
              },
              {
                type: "text",
                item: "That's a lot of stars!",
              },
            ]}
          />
          <div className="mt-3 mb-3">
            <ScrollAnimation
              key={`ScrollAnimation-summaryTransition-4`}
              duration={1}
              animateIn={"fadeInDown"}
              scrollableParentSelector="#scrolly-div"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Icon
                  as={BsThreeDotsVertical}
                  h={10}
                  w={10}
                  m={5}
                  color={"white"}
                />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      )}
      {summary.favoriteStore && (
        <div>
          <SummaryItem
            id="favoriteStore"
            animation="swing"
            color={colors.green}
            summaryName="favoriteStore"
            primaryStat={summary.favoriteStore}
            lines={[
              {
                type: "text",
                item: isPublic
                  ? "Their go-to boba shop is"
                  : "Your go-to boba shop is...",
              },
              {
                type: "largeText",
                item: summary.favoriteStore,
              },
              {
                type: "text",
                item: "Obviously!",
              },
            ]}
          />
          <div className="mt-3 mb-3">
            <ScrollAnimation
              key={`ScrollAnimation-summaryTransition-4`}
              duration={1}
              animateIn={"fadeInDown"}
              scrollableParentSelector="#scrolly-div"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Icon
                  as={BsThreeDotsVertical}
                  h={10}
                  w={10}
                  m={5}
                  color={"white"}
                />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      )}
      {summary.favoriteDrink && (
        <div>
          <SummaryItem
            id="favoriteDrink"
            animation="wobble"
            summaryName="favoriteDrink"
            primaryStat={summary.favoriteDrink}
            lines={[
              {
                type: "text",
                item: isPublic
                  ? "Your favorite drink is..."
                  : "Your favorite drink is...",
              },
              {
                type: "image",
                item: getBobaImg(summary.favoriteDrink),
              },
              {
                type: "text",
                item: summary.favoriteDrink,
              },
            ]}
          />
          <div className="mt-3 mb-3">
            <ScrollAnimation
              key={`ScrollAnimation-summaryTransition-4`}
              duration={1}
              animateIn={"fadeInDown"}
              scrollableParentSelector="#scrolly-div"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Icon
                  as={BsThreeDotsVertical}
                  h={10}
                  w={10}
                  m={5}
                  color={"white"}
                />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      )}
      {summary.favoriteTopping && (
        <div>
          <SummaryItem
            id="favoriteTopping"
            animation="rotateIn"
            summaryName="favoriteTopping"
            color={
              summaryJson.toppings.chakra[summary.favoriteTopping.toLowerCase()]
            }
            primaryStat={summary.favoriteTopping}
            lines={[
              {
                type: "text",
                item: isPublic
                  ? "Their favorite topping is..."
                  : "Your favorite topping is...",
              },
              {
                type: "icon",
                item: RiBubbleChartFill,
              },
              {
                type: "text",
                item: `${summary.favoriteTopping}`,
              },
            ]}
          />
          <div className="mt-3 mb-3">
            <ScrollAnimation
              key={`ScrollAnimation-summaryTransition-4`}
              duration={1}
              animateIn={"fadeInDown"}
              scrollableParentSelector="#scrolly-div"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Icon
                  as={BsThreeDotsVertical}
                  h={10}
                  w={10}
                  m={5}
                  color={"white"}
                />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      )}
      <SummaryItem
        id="end"
        animation="wobble"
        lines={[
          {
            type: "text",
            item: "That's it for now...",
          },
          {
            type: "icon",
            item: IoHandRight,
          },
          {
            type: "text",
            item: "Give us a hi-five!",
          },
        ]}
      />
    </div>
  );
};

export default Summary;
