import React, { useState } from "react";
import { getUserWithUid } from "../helpers/helpers";
import { FriendCard } from "./FriendCard";

export const Following = (props) => {
  const {
    followingList,
    setLoadingFollowingList,
    loadingFollowingList,
    loader,
    loadingUserSettings,
    setLoadingUserSettings,
    setLoadingFriendReviews,
  } = props;

  const [followingUsers, setFollowingUsers] = useState([]);
  const [loadingFollowingUsers, setLoadingFollowingUsers] = useState(true);

  React.useEffect(() => {
    const fetchFollowingUsers = async () => {
      const newFollowingUsers = [];
      if (loadingFollowingUsers && !loadingFollowingList) {
        for (const uid of followingList) {
          const user = await getUserWithUid(uid);
          newFollowingUsers.push({ ...user, uid });
        }
        setFollowingUsers(newFollowingUsers);
        setLoadingFollowingUsers(false);
      }
    };
    fetchFollowingUsers();
  }, [loadingFollowingUsers, followingList, loadingFollowingList]);

  if (loadingFollowingUsers || loadingFollowingList || loadingUserSettings) {
    return loader;
  } else if (followingUsers.length === 0) {
    return (
      <div className="Feed-container d-flex flex-column align-items-center justify-content-center text-center text-white">
        You're not following anyone yet!
      </div>
    );
  } else {
    return (
      <div className="Feed-container d-flex flex-column clearfix">
        <div className="Feed-containerHeader text-bold text-white">{`${followingUsers.length} Following`}</div>
        {!loadingFollowingUsers &&
          !loadingFollowingList &&
          !loadingUserSettings &&
          followingUsers.map((user) => {
            return (
              <FriendCard
                key={`FriendCard-${user.username}`}
                user={user}
                setLoadingUserSettings={setLoadingUserSettings}
                userFollowingList={followingList}
                setLoadingFollowingList={setLoadingFollowingList}
                loadingFollowingList={loadingFollowingList}
                setLoadingFriendReviews={setLoadingFriendReviews}
                feed={true}
              />
            );
          })}
      </div>
    );
  }
};
