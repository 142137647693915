import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { colors } from "../helpers/colors";
import { getTodayDate, isInvalidDate, validateDate } from "../helpers/helpers";
import "../stylesheets/Reviews.css";

export const NewReview = (props) => {
  const firestore = firebase.firestore();
  const reviewsRef = firestore.collection("reviews");
  const auth = firebase.auth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setLoadingReviews } = props;

  const today = getTodayDate();

  const [rating, setRating] = useState(0);
  const [showDateError, setShowDateError] = useState(false);
  const [showReviewMsgError, setShowReviewMsgError] = useState(false);

  const [newReview, setNewReview] = useState({
    createdAt: today,
    drinkName: "",
    storeName: "",
    reviewText: "",
    price: "",
    topping1: "",
    topping2: "",
    topping3: "",
  });

  const disabledSubmit =
    !newReview.createdAt ||
    !newReview.drinkName ||
    !newReview.storeName ||
    !newReview.price ||
    !rating ||
    showDateError ||
    showReviewMsgError;

  const postReview = async (e, onClose) => {
    e.preventDefault();

    const parsedPrice = parseFloat(newReview.price);

    await reviewsRef.add({
      createdAt: new Date(newReview.createdAt),
      drinkName: newReview.drinkName,
      reviewText: newReview.reviewText,
      storeName: newReview.storeName,
      rating: rating,
      price: parsedPrice,
      userId: auth.currentUser.uid,
      topping1: newReview.topping1 || "",
      topping2: newReview.topping2 || "",
      topping3: newReview.topping3 || "",
    });

    setNewReview({
      createdAt: today,
      drinkName: "",
      storeName: "",
      reviewText: "",
      price: "",
      topping1: "",
      topping2: "",
      topping3: "",
      rating: 0,
    });
    setRating(0);
    setLoadingReviews(true);
    onClose();
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "price") {
      value = value.replace(/[^0-9.]/g, "");
    } else if (e.target.name === "createdAt") {
      value = value.replace(/[^0-9/]/g, "").slice(0, 10);
      if (!validateDate(value) || isInvalidDate(value)) {
        setShowDateError(true);
      } else {
        setShowDateError(false);
      }
    } else if (e.target.name === "reviewText") {
      if (value.length > 90) {
        setShowReviewMsgError(true);
      } else {
        setShowReviewMsgError(false);
      }
    }
    setNewReview({
      ...newReview,
      [e.target.name]: value,
    });
  };

  const ToppingsDropdown = (props) => {
    const { formLabel, name } = props;
    return (
      <FormControl>
        <FormLabel mt={5}>{formLabel}</FormLabel>
        <Select
          name={name}
          placeholder="Select topping"
          value={newReview[name]}
          onChange={handleChange}
        >
          <option>Boba</option>
          <option>Clear Boba</option>
          <option>Honey Boba</option>
          <option>Coconut Jelly</option>
          <option>Coffee Jelly</option>
          <option>Grass Jelly</option>
          <option>Lychee Jelly</option>
          <option>Mango Jelly</option>
          <option>Rainbow Jelly</option>
          <option>Strawberry Stars</option>
          <option>Egg Pudding</option>
          <option>Aloe Vera</option>
          <option>Ice Cream</option>
          <option>Basil Seeds</option>
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <div
        className="Reviews-newReviewButton d-flex justify-content-center align-items-center text-center text-bold"
        onClick={onOpen}
      >
        +
      </div>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
        size={"xs"}
        allowPinchZoom={true}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            New Review
            <Button
              type="submit"
              disabled={disabledSubmit}
              colorScheme="blue"
              ml={2}
              mb={1}
              size="xs"
              onClick={(e) => postReview(e, onClose)}
            >
              Submit
            </Button>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired={true}>
              <FormLabel>Date</FormLabel>
              <Input
                name="createdAt"
                value={newReview.createdAt}
                onChange={handleChange}
                placeholder={today}
              />
              {showDateError && (
                <FormHelperText color="red.100">
                  Invalid date input. Please reformat.
                </FormHelperText>
              )}
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel mt={5}>Drink Name</FormLabel>
              <Input
                name="drinkName"
                value={newReview.drinkName}
                onChange={handleChange}
                placeholder="Earl Grey Milk Tea"
              />
            </FormControl>
            <FormControl mt={5} isRequired={true}>
              <FormLabel>Store Name</FormLabel>
              <Input
                name="storeName"
                value={newReview.storeName}
                onChange={handleChange}
                placeholder="Happy Lemon"
              />
            </FormControl>
            <FormControl mt={5} isRequired={true}>
              <FormLabel>Price</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                  children="$"
                />
                <Input
                  name="price"
                  value={newReview.price}
                  onChange={handleChange}
                  placeholder="3.75"
                />
              </InputGroup>
              <FormControl mt={5} isRequired={true}>
                <FormLabel>Rating</FormLabel>
                <ReactStars
                  name="rating"
                  value={rating}
                  count={5}
                  size={24}
                  activeColor={colors.yellow}
                  emptyIcon={<i className="far fa-star"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  onChange={setRating}
                  isHalf={true}
                />
              </FormControl>
            </FormControl>
            <ToppingsDropdown formLabel="Topping 1" name="topping1" />
            <ToppingsDropdown formLabel="Topping 2" name="topping2" />
            <ToppingsDropdown formLabel="Topping 3" name="topping3" />
            <FormControl mt={5} mb={20}>
              <FormLabel>Review</FormLabel>
              <Textarea
                name="reviewText"
                value={newReview.reviewText}
                onChange={handleChange}
              />
              {showReviewMsgError && (
                <FormHelperText color="red.100">
                  Too many characters.
                </FormHelperText>
              )}
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
