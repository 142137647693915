import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Login } from "../src/components/Login";
import { NotFound } from "../src/components/NotFound";
import { Root } from "../src/components/Root";
import "./App.css";
import { NotFoundAuth } from "./components/NotFoundAuth";
import { Fonts } from "./Fonts";

const theme = extendTheme({
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
  colors: {
    blue: {
      50: "#2AA9DF",
      100: "#93D4EF", // boba
      150: "#9FDFD4", // coconut jelly
    },
    red: {
      50: "#ECADB5", // clear boba
      100: "#E78687", // strawberry stars
      150: "#F4B8AB", // rainbow jelly
      200: "#F5ACCB", // ice cream
    },
    yellow: {
      50: "#F8CB9B", // honey boba
      100: "#FCC96C", // mango jelly
      150: "#EED289", // egg pudding
    },
    purple: {
      50: "#C9B6FF", // lychee jelly
    },
    orange: {
      50: "#DCB097", // basil seeds
      900: "#AB918D", // coffee jelly
    },
    green: {
      50: "#C5DB97", // grass jelly
      100: "#99C692", // aloe vera
    },
    gray: {
      900: "#50555c", // text
    },
  },
});

firebase.initializeApp({
  apiKey: "AIzaSyDayn2HIOKxxVvTRVjYbowqsjAKiTWLctE",
  authDomain: "teatracker-6b716.firebaseapp.com",
  projectId: "teatracker-6b716",
  storageBucket: "teatracker-6b716.appspot.com",
  messagingSenderId: "298719435220",
  appId: "1:298719435220:web:517e8265602799cfb39e41",
  measurementId: "G-P54G01MQTH",
});

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const auth = firebase.auth();

function Home() {
  const [user] = useAuthState(auth);
  return (
    <div className="App">
      <section>{user ? <Root userExists={true} /> : <Login />}</section>
    </div>
  );
}

function Profile(props) {
  const [user] = useAuthState(auth);
  const fromFeed =
    "location" in props &&
    "state" in props.location &&
    "fromFeed" in props.location.state &&
    props.location.state.fromFeed;
  return (
    <div className="App">
      <section>
        {user ? (
          <Root
            profileUsername={props.match.params.username}
            fromFeed={fromFeed}
          />
        ) : (
          <Login />
        )}
      </section>
    </div>
  );
}

function PageNotFound() {
  const [user] = useAuthState(auth);
  return (
    <div className="App">
      <section>{user ? <NotFoundAuth /> : <NotFound />}</section>
    </div>
  );
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/feed" component={Home} />
        <Route exact path="/user/:username" component={Profile} />
        <Route exact path="/404" component={PageNotFound} />
      </Router>
    </ChakraProvider>
  );
}

export default App;
