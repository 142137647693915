import { capitalize } from "../helpers/helpers";
import "../stylesheets/GlanceCards.css";
import summaryJson from "../summary.json";
import { Tag } from "./Tag";

export const GlanceCard = (props) => {
  const { label, stat, image, highlightColor, size, tag, isDrinkCount } = props;
  const containerClassName = `GlanceCard-container d-flex flex-row align-items-center justify-content-start GlanceCard-${size}`;
  const textClassName =
    size === "lg"
      ? "GlanceCard-textContainerLg d-flex flex-row justify-content-between align-items-center text-align-center"
      : "d-flex flex-column justify-content-center align-items-center text-align-center";

  return (
    <div className={containerClassName}>
      <img
        style={{ height: 35, marginRight: 10 }}
        src={image}
        alt="boba icon"
      />
      <div className={textClassName}>
        <div className="GlanceCard-label text-medium">{label}</div>
        {stat ? (
          tag ? (
            <Tag
              size="md"
              label={stat}
              color={summaryJson.toppings.hex[stat.toLowerCase()]}
            />
          ) : (
            <div
              style={{ color: highlightColor }}
              className="GlanceCard-stat text-bold"
            >
              {isDrinkCount ? stat : capitalize(stat)}
            </div>
          )
        ) : (
          <div
            style={{ color: highlightColor }}
            className="GlanceCard-addMoreText text-bold"
          >
            Add more reviews
          </div>
        )}
      </div>
    </div>
  );
};
