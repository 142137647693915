import { Icon } from "@chakra-ui/react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { IoChevronBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import boba from "../assets/sad_boba.png";

export const NotFound = (props) => {
  const { isAuth } = props;
  const matches = useMediaQuery("(min-width:479px)");

  let styles = {};
  if (isAuth) {
    if (matches) {
      styles["flex"] = 0.9;
    } else {
      styles["height"] = "78vh";
    }
    styles["justifyContent"] = "center";
  } else {
    styles["height"] = "100vh";
    styles["paddingTop"] = 132;
  }

  return (
    <div style={styles} className="NotFound-page d-flex flex-column">
      {isAuth && (
        <Link to="/feed">
          <div className="Main-back">
            <Icon as={IoChevronBack} w={7} h={7} color="gray.900" />
          </div>
        </Link>
      )}
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={boba} className="NotFound-boba" alt="boba" />
        <div className="NotFound-header text-normal">Boba not found.</div>
        <div className="NotFound-text text-normal">
          UnBOBAlievable! We can't seem to find what you're looking for.
        </div>
      </div>
    </div>
  );
};
