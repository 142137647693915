export const computeTotalOrders = (reviews) => {
  return reviews.length;
};

export const computeFavoriteItemSummaries = (reviews) => {
  let summary = {
    drinkName: {
      favorite: "",
      maxNumOrders: 0,
      favoriteToNumOrders: {},
    },
    storeName: {
      favorite: "",
      maxNumOrders: 0,
      favoriteToNumOrders: {},
    },
  };
  const categories = ["drinkName", "storeName"];

  categories.forEach((category) => {
    reviews.forEach((review) => {
      if (!review[category]) {
        return;
      }
      const reviewItem = review[category].toLowerCase();
      if (!(reviewItem in summary[category].favoriteToNumOrders)) {
        summary[category].favoriteToNumOrders[reviewItem] = 1;
      } else {
        summary[category].favoriteToNumOrders[reviewItem] += 1;
        if (
          summary[category].favoriteToNumOrders[reviewItem] >
          summary[category].maxNumOrders
        ) {
          summary[category].favorite = reviewItem;
          summary[category].maxNumOrders =
            summary[category].favoriteToNumOrders[reviewItem];
        }
      }
    });
  });

  return summary;
};

export const computeFavoriteTopping = (reviews) => {
  let mapToppingsToNumOrders = {};
  let favoriteTopping = "";
  let maxNumOrders = 0;

  const toppings = ["topping1", "topping2", "topping3"];

  toppings.forEach((topping) => {
    reviews.forEach((review) => {
      if (topping in review && review[topping] !== "") {
        const reviewTopping = review[topping].toLowerCase();
        if (!(reviewTopping in mapToppingsToNumOrders)) {
          mapToppingsToNumOrders[reviewTopping] = 1;
        } else {
          mapToppingsToNumOrders[reviewTopping] += 1;
          if (mapToppingsToNumOrders[reviewTopping] > maxNumOrders) {
            favoriteTopping = reviewTopping;
            maxNumOrders = mapToppingsToNumOrders[reviewTopping];
          }
        }
      }
    });
  });

  return favoriteTopping;
};

export const computeTotalSpent = (reviews) => {
  let totalSpent = 0;
  reviews.forEach((review) => {
    if ("price" in review && typeof review.price === "number") {
      totalSpent += review.price;
    }
  });
  return totalSpent.toFixed(2);
};

export const computeAverageRating = (reviews) => {
  let totalNumReviews = reviews.length;
  let totalNumStars = 0;
  reviews.forEach((review) => {
    if ("rating" in review && typeof review.rating === "number") {
      totalNumStars += review.rating;
    }
  });
  return parseFloat((totalNumStars / totalNumReviews).toFixed(2));
};
