import { useHistory } from "react-router-dom";
import "../stylesheets/FriendCard.css";
import { Follow } from "./Follow";

export const FriendCard = (props) => {
  const {
    user,
    setLoadingUserSettings,
    userFollowingList,
    setLoadingFollowingList,
    setLoadingFriendReviews,
    loadingFollowingList,
    feed,
  } = props;
  const history = useHistory();

  const location = {
    pathname: `/user/${user.username}`,
    state: {
      fromFeed: true,
    },
  };

  return (
    <div className="FriendCard d-flex flex-row justify-content-between align-items-center">
      <div
        className="d-flex flex-row align-items-center"
        style={{ cursor: "pointer" }}
        onClick={() => history.push(location)}
      >
        <div>
          <img
            className="FriendCard-avatar"
            rel="noreferrer"
            referrerPolicy="origin"
            alt="follower avatar"
            src={user.photoUrl}
          />
        </div>
        <div className="d-flex flex-column ml-3">
          <div className="FriendCard-username text-bold">{user.username}</div>
          <div className="FriendCard-name">
            {user.displayName.split(" ")[0]}
          </div>
        </div>
      </div>
      <div>
        <Follow
          enableFollowButton={user.public}
          // The user in the following list
          targetUserId={user.uid}
          // The authorized user
          setLoadingUserSettings={setLoadingUserSettings}
          setLoadingFollowingList={setLoadingFollowingList}
          loadingFollowingList={loadingFollowingList}
          currFollowingList={userFollowingList}
          setLoadingFriendReviews={setLoadingFriendReviews}
          // To render feed-style follow button
          feed={feed}
        />
      </div>
    </div>
  );
};
