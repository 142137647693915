import React from "react";
import { useHistory } from "react-router-dom";
import SelectSearch from "react-select-search";
import "../stylesheets/FriendSearchAutocomplete.css";

export const FriendSearchAutocomplete = (props) => {
  const { allUsers } = props;
  const history = useHistory();

  function renderFriend(props, option, snapshot, className) {
    const imgStyle = {
      borderRadius: "50%",
      verticalAlign: "middle",
      marginRight: 10,
    };

    return (
      <button {...props} className={className} type="button">
        <span className="d-flex flex-row align-items-center">
          <img
            alt=""
            style={imgStyle}
            width="32"
            height="32"
            src={option.photoUrl}
          />
          <span className="d-flex flex-column ml-2">
            <span className="text-bold">@{option.name}</span>
            <span>{option.displayName.split(" ")[0]}</span>
          </span>
        </span>
      </button>
    );
  }

  const handleChangeUser = (value) => {
    const location = {
      pathname: `/user/${value}`,
      state: {
        fromFeed: true,
      },
    };
    history.push(location);
  };

  return (
    <SelectSearch
      options={allUsers}
      onChange={handleChangeUser}
      renderOption={renderFriend}
      search
      placeholder="Search friends by username"
      autoComplete="on"
    />
  );
};
