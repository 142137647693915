import moment from "moment";
import "../stylesheets/Calendar.css";
import { Tooltip } from "./Tooltip";

// built with https://programmingwithmosh.com/react/build-a-react-calendar-component-from-scratch/
export const Calendar = (props) => {
  // List of date objects where each object represents
  // a day with at least one review.
  const { createdAtList } = props;

  const daysWithReviews = [];
  Object.keys(createdAtList).forEach((d) => {
    daysWithReviews.push(d);
  });

  const weekdaysShort = moment.weekdaysShort();
  const weekdaysShortName = weekdaysShort.map((day) => {
    return (
      <th key={day} className="Calendar-day text-bold">
        {day[0].toUpperCase()}
      </th>
    );
  });

  // Gets index of first day of month. 0 = sunday, 6 = saturday.
  const getFirstDayOfMonth = () => {
    return moment().clone().startOf("month").format("d");
  };

  // Blank spots on the first row of the calendar.
  let blanks = [];
  const numBlanks = parseInt(getFirstDayOfMonth());
  for (var i = 0; i < numBlanks; i += 1) {
    blanks.push(
      <td key={`blankDay-${i}`} className="Calendar-day Calendar-empty">
        {" "}
      </td>
    );
  }

  // Give the start and end dates of an interval rounded borders.
  const getReviewClassNames = (d) => {
    var classNames = "Calendar-day text-bold";
    if (daysWithReviews.includes(d.toString())) {
      // start
      classNames = classNames.concat(" Calendar-reviewDay");
      if (
        (d + numBlanks) % 7 === 1 ||
        !daysWithReviews.includes((d - 1).toString())
      ) {
        classNames = classNames.concat(" Calendar-reviewDayStart");
      }
      // end
      if (
        (d + numBlanks) % 7 === 0 ||
        !daysWithReviews.includes((d + 1).toString())
      ) {
        classNames = classNames.concat(" Calendar-reviewDayEnd");
      }
    }
    return classNames;
  };

  let daysInMonth = [];
  for (var d = 1; d <= moment().clone().daysInMonth(); d++) {
    const classNames = getReviewClassNames(d);
    if (d in createdAtList) {
      daysInMonth.push(
        <td key={d} className={classNames}>
          <Tooltip content={createdAtList[d]}>{d}</Tooltip>
        </td>
      );
    } else {
      daysInMonth.push(
        <td key={d} className={classNames}>
          {d}
        </td>
      );
    }
  }

  let totalSlots = [...blanks, ...daysInMonth];
  let rows = []; // list of weeks
  let cells = []; // a list representing one week containing each day

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      // building row for this week
      cells.push(row);
    } else {
      // add week row to rows
      rows.push(cells);
      cells = [];
      cells.push(row); // start on sundays
    }
    if (i === totalSlots.length - 1) {
      // the last week may not have seven days
      rows.push(cells);
    }
  });

  daysInMonth = rows.map((d, i) => {
    return <tr key={`dayInMonth-${i}`}>{d}</tr>;
  });

  const month = () => {
    return moment().clone().format("MMMM");
  };

  return (
    <div className="Calendar">
      <div className="Calendar-month text-bold">{month().toUpperCase()}</div>
      <table className="Calendar-table">
        <thead>
          <tr>{weekdaysShortName}</tr>
        </thead>
        <tbody>{daysInMonth}</tbody>
      </table>
    </div>
  );
};
