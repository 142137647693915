import { SettingsIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  storeNewLocation,
  storeNewUsername,
  storeProfileIsPublic,
  userWithUsernameExists,
} from "../helpers/helpers";

export const Settings = (props) => {
  const { uid, userSettings, setUserSettings } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newUserSettings, setNewUserSettings] = useState({ ...userSettings });
  const [validating, setValidating] = useState(false);
  const [showError, setShowError] = useState(false);

  React.useEffect(() => {
    const checkUserExists = async () => {
      setValidating(true);
      const usernameWithoutWhitespaces = newUserSettings.username.trim();
      const usernameExists = await userWithUsernameExists(
        usernameWithoutWhitespaces
      );
      if (
        usernameExists &&
        newUserSettings.username !== userSettings.username
      ) {
        setShowError(true);
      } else {
        setShowError(false);
      }
      setValidating(false);
    };
    checkUserExists();
  }, [newUserSettings.username, userSettings.username]);

  const updateSettings = async (e) => {
    e.preventDefault();
    if (newUserSettings.location !== userSettings.location) {
      await storeNewLocation(uid, newUserSettings.location);
    }
    if (newUserSettings.public !== userSettings.public) {
      await storeProfileIsPublic(uid, newUserSettings.public);
    }
    if (newUserSettings.username !== userSettings.username) {
      const successUsername = await storeNewUsername(
        uid,
        newUserSettings.username
      );
      if (!successUsername) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    }
    if (!showError) {
      setUserSettings({ ...newUserSettings });
      onClose();
    }
  };

  const handleChange = async (e) => {
    let value = e.target.value;
    if (e.target.name === "public") {
      value = value === "true" ? true : false;
    }
    setNewUserSettings({ ...newUserSettings, [e.target.name]: value });
  };

  const closeModal = () => {
    setNewUserSettings(userSettings);
    onClose();
  };

  return (
    <>
      <SettingsIcon
        style={{ cursor: "pointer" }}
        w={7}
        h={7}
        color="white"
        onClick={onOpen}
      />

      <Modal size="xs" isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired={true}>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                name="username"
                value={newUserSettings.username}
                onChange={handleChange}
                autoComplete="off"
              />
              {showError && (
                <FormHelperText color="red.100">
                  Username taken. Please try again.
                </FormHelperText>
              )}
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Location</FormLabel>
              <Input
                type="text"
                name="location"
                value={newUserSettings.location}
                onChange={handleChange}
                autoComplete="off"
              />
            </FormControl>
            <FormControl mt={5} display="flex" alignItems="center">
              <FormLabel mb="0">Public Profile</FormLabel>
              <Switch
                mt={3}
                colorScheme="green"
                name="public"
                id="public"
                isChecked={newUserSettings.public}
                value={(!newUserSettings.public).toString()}
                onChange={handleChange}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              type="submit"
              disabled={!newUserSettings.username || showError || validating}
              colorScheme="blue"
              onClick={async (e) => {
                await updateSettings(e);
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
