import firebase from "firebase/app";
import "firebase/auth";
import boba from "../assets/logo.png";
import { Tag } from "../components/Tag";
import { colors } from "../helpers/colors";
import "../stylesheets/Login.css";

export const Login = () => {
  const auth = firebase.auth();
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };
  return (
    <div className="Login-container d-flex flex-column align-items-center">
      <img style={{ height: 197, marginTop: 132 }} src={boba} alt="boba logo" />
      <div
        style={{ color: colors.darkBrown, fontSize: 21 }}
        className="text-bold mt-1 mb-3"
      >
        tea tracker
      </div>
      <Tag
        label="Login"
        color={colors.blue}
        size="xl"
        onClick={signInWithGoogle}
      />
    </div>
  );
};
