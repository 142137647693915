import useMediaQuery from "@material-ui/core/useMediaQuery";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { useState } from "react";
import Loader from "react-loader-spinner";
import bg from "../assets/bg2.png";
import { colors } from "../helpers/colors";
import {
  getAllUsers,
  getFollowerNotifications,
  getFollowingList,
  getFriendReviews,
} from "../helpers/helpers";
import "../stylesheets/Feed.css";
import { SmallScreenFeedDrawer } from "./FeedDrawer";
import { Followers } from "./Followers";
import { Following } from "./Following";
import { FriendReviews } from "./FriendReviews";
import { FriendSearchAutocomplete } from "./FriendSearchAutocomplete";
import { Tag } from "./Tag";

export const Feed = (props) => {
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  const { setLoadingUserSettings, loadingUserSettings } = props;

  const [friendReviews, setFriendReviews] = useState([]);
  const [loadingFriendReviews, setLoadingFriendReviews] = useState(true);
  const reviewsRef = firestore.collection("reviews");
  const [followingList, setFollowingList] = useState([]);
  const [loadingFollowingList, setLoadingFollowingList] = useState(true);

  const [followerNotifs, setFollowerNotifs] = useState([]);
  const [loadingFollowerNotifs, setLoadingFollowerNotifs] = useState(true);

  const [allUsers, setAllUsers] = useState([]);
  const [loadingAllUsers, setLoadingAllUsers] = useState(true);

  const [display, setDisplay] = useState("feed");
  const currUserId = auth.currentUser.uid;

  function handleChangeDisplay(e, displayType) {
    e.preventDefault();
    if (display !== displayType) {
      setDisplay(displayType);
    }
  }

  React.useEffect(() => {
    const fetchAllUsers = async () => {
      if (loadingAllUsers) {
        const users = await getAllUsers();
        setAllUsers(users);
        setLoadingAllUsers(false);
      }
    };
    fetchAllUsers();
  }, [loadingAllUsers]);

  // Retrieve the list of people who follow the user.
  // TODO: move this to notifications.
  React.useEffect(() => {
    const fetchFollowerList = async () => {
      const followerList = await getFollowerNotifications(currUserId);
      if (followerList && followerList.length > 0) {
        setFollowerNotifs(followerList);
      }
      setLoadingFollowerNotifs(false);
    };
    fetchFollowerList();
  }, [currUserId]);

  // Retrieve the list of people the user is following.
  React.useEffect(() => {
    const fetchFollowingList = async () => {
      if (loadingFollowingList) {
        const newFollowingList = await getFollowingList(currUserId);
        setFollowingList(newFollowingList);
        setLoadingFollowingList(false);
      }
    };
    fetchFollowingList();
  }, [currUserId, loadingFollowingList]);

  // Load this user's friends' reviews.
  React.useEffect(() => {
    if (loadingFriendReviews && !loadingFollowingList) {
      const fetchReviews = async () => {
        if (followingList.length === 0) {
          setFriendReviews([]);
          setLoadingFriendReviews(false);
          return;
        }
        const friendReviews = await getFriendReviews(followingList);
        setFriendReviews(friendReviews);
        setLoadingFriendReviews(false);
      };

      fetchReviews();
    } else if (!loadingFollowingList && followingList.length === 0) {
      setLoadingFriendReviews(false);
    }
  }, [
    followingList,
    currUserId,
    loadingFollowingList,
    loadingFriendReviews,
    reviewsRef,
  ]);

  const loader = (
    <div className="Feed-container d-flex flex-column align-items-center justify-content-center">
      <Loader
        type="ThreeDots"
        color={colors.slightlyDarkerBlue}
        height={80}
        width={80}
      />
    </div>
  );

  const matches = useMediaQuery("(min-width:479px)");
  const smallPhone = useMediaQuery("(max-height:680px)");

  return (
    <div
      style={{
        backgroundImage: matches ? `url(${bg})` : "",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className="Feed-page d-flex flex-column justify-content-between align-items-center clearfix"
    >
      {smallPhone && (
        <SmallScreenFeedDrawer
          handleChangeDisplay={handleChangeDisplay}
          display={display}
        />
      )}
      <div className="d-flex flex-column justify-content-center align-items-start">
        <div className="Feed-title text-bold text-left">Friends</div>
        <div
          className="Feed-search d-flex flex-row justify-content-center"
          style={{ flex: 0.5 }}
        >
          {!loadingAllUsers ? (
            <FriendSearchAutocomplete allUsers={allUsers} />
          ) : (
            <Loader
              type="ThreeDots"
              color={colors.slightlyDarkerBlue}
              height={30}
              width={30}
            />
          )}
        </div>
      </div>
      {!smallPhone && (
        <div className="Feed-tabs d-flex flex-row justify-content-between">
          <Tag
            label="Feed"
            color={colors.blue}
            size="lg"
            outline={display === "feed" ? false : true}
            onClick={(e) => handleChangeDisplay(e, "feed")}
          />
          <Tag
            label="Following"
            color={colors.blue}
            size="lg"
            outline={display === "following" ? false : true}
            onClick={(e) => handleChangeDisplay(e, "following")}
          />
          <Tag
            label="Followers"
            color={colors.blue}
            size="lg"
            outline={display === "followers" ? false : true}
            onClick={(e) => handleChangeDisplay(e, "followers")}
          />
        </div>
      )}
      {display === "feed" && (
        <FriendReviews
          loadingFollowingList={loadingFollowingList}
          loadingFriendReviews={loadingFriendReviews}
          loadingFollowerNotifs={loadingFollowerNotifs}
          loader={loader}
          friendReviews={friendReviews}
          followerNotifs={followerNotifs}
        />
      )}
      {display === "following" && (
        <Following
          followingList={followingList}
          loadingFollowingList={loadingFollowingList}
          loader={loader}
          setLoadingFollowingList={setLoadingFollowingList}
          setLoadingFriendReviews={setLoadingFriendReviews}
          setLoadingUserSettings={setLoadingUserSettings}
          loadingUserSettings={loadingUserSettings}
        />
      )}
      {display === "followers" && (
        <Followers
          followerList={followerNotifs}
          loadingFollowerList={loadingFollowerNotifs}
          followingList={followingList}
          loadingFollowingList={loadingFollowingList}
          loader={loader}
          setLoadingFollowingList={setLoadingFollowingList}
          setLoadingFriendReviews={setLoadingFriendReviews}
          setLoadingUserSettings={setLoadingUserSettings}
          loadingUserSettings={loadingUserSettings}
        />
      )}
    </div>
  );
};
