import { Button, Icon } from "@chakra-ui/react";
import firebase from "firebase/app";
import { useState } from "react";
import { GoCheck, GoPlus } from "react-icons/go";
import { RiUserAddLine, RiUserUnfollowLine } from "react-icons/ri";
import Loader from "react-loader-spinner";
import { colors } from "../helpers/colors";
import { setFollower, setFollowing } from "../helpers/helpers";
import "../stylesheets/Follow.css";

export const Follow = (props) => {
  const auth = firebase.auth();
  const userId = auth.currentUser.uid;
  const {
    enableFollowButton,
    targetUserId,
    setLoadingUserSettings,
    currFollowingList,
    loadingFollowingList,
    setLoadingFollowingList,
    setLoadingFriendReviews,
    feed,
  } = props;

  const [loadingSetFollow, setLoadingSetFollow] = useState(false);

  if (loadingFollowingList || loadingSetFollow) {
    return (
      <Loader type="TailSpin" color={colors.blue} height={10} width={10} />
    );
  }

  const isFollowing = currFollowingList.includes(targetUserId);

  const handleSetFollow = async (e) => {
    e.preventDefault();
    setLoadingSetFollow(true);
    await setFollowing(userId, targetUserId, !isFollowing, currFollowingList);
    await setFollower(targetUserId, userId, !isFollowing);
    setLoadingUserSettings(true);
    if (setLoadingFollowingList) {
      setLoadingFollowingList(true);
    }
    if (setLoadingFriendReviews) {
      setLoadingFriendReviews(true);
    }
    setLoadingSetFollow(false);
  };

  const getFollowButton = () => {
    if (feed) {
      if (enableFollowButton) {
        return (
          <div className="Follow-followIcon">
            <Icon as={RiUserAddLine} color="white" onClick={handleSetFollow} />
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return (
        <Button
          leftIcon={<GoPlus />}
          size="xs"
          colorScheme="blue"
          variant="outline"
          mt={2}
          onClick={handleSetFollow}
        >
          Follow
        </Button>
      );
    }
  };

  const getUnfollowButton = () => {
    if (feed) {
      return (
        <div className="Follow-icon">
          <Icon as={RiUserUnfollowLine} onClick={handleSetFollow} />
        </div>
      );
    } else {
      return (
        <Button
          leftIcon={<GoCheck />}
          size="xs"
          colorScheme="blue"
          variant="solid"
          mt={2}
          onClick={handleSetFollow}
        >
          Following
        </Button>
      );
    }
  };

  return !isFollowing ? getFollowButton() : getUnfollowButton();
};
