import React, { useState } from "react";
import { truncate } from "../helpers/helpers";
import "../stylesheets/Tooltip.css";

// from https://dev.to/vtrpldn/how-to-make-an-extremely-reusable-tooltip-component-with-react-and-nothing-else-3pnk
export const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  if (!props.content) {
    return;
  }

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const getTooltipContent = () => {
    let content = props.content;
    if (content.length > 2) {
      content = props.content.slice(0, 2);
      content.push(`and ${props.content.length - 2} more`);
    }
    let contentTruncated = [];
    content.forEach((drink) => {
      contentTruncated.push(truncate(drink, 15));
    });
    return contentTruncated.join("\n");
  };

  return (
    <div
      className={`Tooltip-Wrapper`}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div
          // style={props.content && tooltipClass}
          className={`Tooltip-Tip ${props.direction || "top"}`}
        >
          {/* Content */}
          {getTooltipContent()}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
